import React, { useCallback, useContext, useEffect, useState } from "react";

import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { userStore } from "../../Services/store";
import { formatNumber, weightedWords } from "../../Services/constantsAndTools";
import Divider from "@mui/material/Divider";
import { DateTimeEntry } from "./DateTimeEntry";
import makeStyles from "@mui/styles/makeStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];

const initialState = {
  refreshEvery: 30000,
  refresh: 0,
  autoRefresh: false,
  multiEditSelected: []
};
const useStyles = makeStyles((theme) => ({
  messageDetails: {
    display: "inline"
  },
  messageDivider: {
    marginTop: 5,
    marginBottom: 5
  }
}));
export default function List(props) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = useState(initialState);
  const [selectedProject, setSelectedProject] = useState(null);
  const [open, setOpen] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openMultistats, setOpenMultiStats] = useState(false);
  //const [mtMatches0, setMtMatches] = useState(0);
  const [stats, setStats] = useState(null);
  const [multiEdit, setMultiEdit] = useState([]);
  const [multiComplete, setMultiComplete] = useState([]);
  const [multiStatsJobs, setMultiStatsJobs] = useState([]);
  const [calculateWWCMulti, setCalculateWWCMulti] = useState([]);
  const [selectedItems, _setSelectedItems] = React.useState([]);
  const [multiStats, setMultiStats] = useState(null);
  const [analysisErrorMessage, setAnalysisErrorMessage] = useState("");
  const [selectedElement, setSelectedElement] = useState(null);
  const [note, setNote] = useState("");
  const onSelectProject = (id) => setSelectedProject(id);
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.autoRefresh) {
        setState((prevState) => ({
          ...prevState,
          refresh: prevState.refresh + 1
        }));
      }
    }, state.refreshEvery);

    return () => clearInterval(interval);
  }, [state.autoRefresh, state.refreshEvery]);

  const setSelectedItems = (items) => {
    _setSelectedItems(items.reduce((acc, x) => [...acc, x.atmsJobId], []));
    setMultiStatsJobs(items);
    setCalculateWWCMulti(items.filter((m) => m.weightedWordCount === null));

    setMultiEdit(items.filter((m) => m.statusForVendors === "NEW"));
    setMultiComplete(items.filter((m) => m.statusForVendors === "TRANSLATED"));
  };

  const tableOptions = {
    maxHeight: 600,
    columns: [
      {
        isCheckbox: false,
        name: "Messages",
        description: "Messages",
        sortable: false,
        align: "left",
        filter: {
          enabled: false
        },
        size: "70px"
      },
      {
        isCheckbox: false,
        name: "BatchId",
        description: "Batch Id",
        sortable: false,
        align: "left",
        filter: {
          enabled: false
        },
        size: "70px"
      },
      {
        isCheckbox: false,
        name: "ProjectName",
        description: "Project Name",
        sortable: true,
        align: "left",
        filter: {
          enabled: false
        },
        size: "100px"
      },
      {
        isCheckbox: false,
        name: "FileName",
        description: "Job File Name",
        sortable: true,
        align: "left",
        filter: {
          enabled: false
        },
        size: "100px"
      },
      {
        name: "Division",
        description: "Division",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "SourceLanguage",
        description: "Source Language",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "TargetLanguage",
        description: "Target Language",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "TotalWC",
        description: "Total WC",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      // {
      //   name: "WCBreakdown",
      //   description: "WC Breakdown",
      //   sortable: false,
      //   filter: {
      //     enabled: false
      //   },
      //   size: "50px"
      // },
      {
        name: "WeightedWC",
        description: "Weighted WC",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "PercentCompleted",
        description: "Percent Completed",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "WorkflowStep",
        description: "Workflow Step",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "JobStatus",
        description: "Job Status",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "VendorDeadline",
        description: "Translation Deadline",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "ReviewDueDate",
        description: "Revision Deadline",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "Assigned Vendor",
        description: "Assigned Vendor",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "50px"
      }
    ],

    additionalFilters: [
      {
        name: "PantherDeadlineForVendorsPeriod",
        description: "Translation Deadline",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 0,
          value: periodLogs,
          options: {
            addNullPeriod: false
          }
        },
        size: 3
      },
      {
        name: "PantherDeadlineForReviewersPeriod",
        description: "Revision Deadline",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 0,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      },

      {
        name: "ProjectName",
        description: "Project Name",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },
      {
        name: "JobFileName",
        description: "File Name",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },

      {
        name: "SourceLanguage",
        description: "Source Language",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/atms-direct/dropdown/languages`,
            multiple: true,
            liveOptions: true,
            checkbox: true,
            forceAvatar: false
          }
        },
        size: 4
      },
      {
        name: "TargetLanguage",
        description: "Target Language",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/atms-direct/dropdown/languages`,
            multiple: true,
            liveOptions: true,
            checkbox: true,
            forceAvatar: false
          }
        },
        size: 4
      },
      {
        name: "StatusAtmsProject",
        description: "Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          defaultValue: [
            { id: "NEW" },
            { id: "ASSIGNED" },
            { id: "COMPLETED" },
            { id: "TRANSLATED" },
            { id: "DECLINED" }
          ],
          options: {
            url: `api/atms-tracker/get-statuses`,
            multiple: true,
            liveOptions: true,
            checkbox: true,
            forceAvatar: false
          }
        },
        size: 3
      },
      {
        name: "WorkflowStep",
        description: "Workflow Step",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/atms-direct/get-workflows`,
            multiple: true,
            liveOptions: true,
            checkbox: true,
            forceAvatar: false
          }
        },
        size: 4
      },
      {
        name: "Division",
        description: "Division",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },
      {
        name: "BatchId",
        description: "Batch Id",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Tracker"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  function onMultiEdit(checked, element) {
    let el = {
      atmsJobId: element.atmsJobId
    };
    element.selected = checked;
    if (checked) {
      AddMultiEdit(el);

      //setLocalEntry({ ...localEntry, langItem: element });
    } else {
      RemoveMultiEdit(el);
      // setLocalEntry({ ...localEntry, langItem: element });
    }
  }

  function AddMultiEdit(element) {
    let el = element;
    let tmp = multiEdit;
    let newEl = tmp.find((m) => m.atmsJobId === element.atmsJobId);
    if (newEl === undefined) {
      tmp.push(el);
    }
    setMultiEdit(tmp);

    setState((prevState) => ({ ...prevState, multiEditSelected: multiEdit }));
    // setState((prevState) => ({ ...prevState, multiEditsetSelected: tmp }));
  }

  function calculateMultiWeightWC() {
    if (calculateWWCMulti.length < 101) {
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Generating WWC", loading: true }
      });
      api
        .post("/api/atms-direct/calculate-wighted-wc-multi", calculateWWCMulti)
        .then((response) => {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "WWC Generated",
              type: "success",
              loading: false
            }
          });
          let rfNew = state.refresh + 1;
          // console.log(rfNew);
          setState((prevState) => ({
            ...prevState,
            refresh: rfNew
          }));
        })
        .catch((error) => errorManagement.formErrors(error, homeDispatch));
    } else {
      homeDispatch({
        type: "NOTIFICATION",
        data: {
          message: "Too many jobs selected: max 100 jobs.",
          type: "warning",
          loading: false
        }
      });
    }
  }

  function mergeStats() {
    if (multiStatsJobs.length < 101) {
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Getting stats", loading: true }
      });
      api
        .post("/api/atms-direct/get-jobs-stats", multiStatsJobs)
        .then((response) => {
          setMultiStats(response.data.data);
          setOpenMultiStats(true);
          setAnalysisErrorMessage(response.data.errorMessage);

          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "Stats Downloaded",
              type: "success",
              loading: false
            }
          });
          let rfNew = state.refresh + 1;
          // console.log(rfNew);
          setState((prevState) => ({
            ...prevState,
            refresh: rfNew
          }));
        })
        .catch((error) => errorManagement.formErrors(error, homeDispatch));
    } else {
      homeDispatch({
        type: "NOTIFICATION",
        data: {
          message: "Too many jobs selected: max 100 jobs.",
          type: "warning",
          loading: false
        }
      });
    }
  }

  function completeJobs() {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Completing Jobs in ATMS", loading: true }
    });
    api
      .post("/api/atms-direct/complete-jobs", multiComplete)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message:
              "Job Completed: Note, it might take 10 minutes to replicate accept in the tracker",
            type: "success",
            loading: false
          }
        });
        let rfNew = state.refresh + 1;
        // console.log(rfNew);
        setState((prevState) => ({
          ...prevState,
          refresh: rfNew
        }));
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }

  function acceptJobs() {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Accepting Job in ATMS", loading: true }
    });
    api
      .post("/api/atms-direct/accept-jobs", multiEdit)
      .then((response) => {
        //setMtMatches(response.data.machineTranslationMatches.match0);

        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message:
              "Job Accepted: Note, it might take 10 minutes to replicate accept in the tracker",
            type: "success",
            loading: false
          }
        });
        let rfNew = state.refresh + 1;
        // console.log(rfNew);
        setState((prevState) => ({
          ...prevState,
          refresh: rfNew
        }));
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }

  function RemoveMultiEdit(element) {
    let tmp = multiEdit;
    let el = tmp.find((m) => m.atmsJobId === element.atmsJobId);
    let index = tmp.indexOf(el);

    if (index > -1) {
      let res = tmp.splice(index, 1);
      setState((prevState) => ({ ...prevState, multiEditSelected: multiEdit }));
    } // setState((prevState) => ({ ...prevState, multio  EditsetSelected: tmp }));
  }

  function acceptJob(localEntry) {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Accepting Job in ATMS", loading: true }
    });
    api
      .post("/api/atms-direct/accept-job/" + localEntry.atmsJobId)
      .then((response) => {
        //console.log(response.data);
        //setMtMatches(response.data.machineTranslationMatches.match0);

        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message:
              "Job Accepted: Note, it might take 10 minutes to replicate accept in the tracker",
            type: "success",
            loading: false
          }
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }

  const showDialog = useCallback((element) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Loading", loading: true }
    });
    api
      .get(
        "/api/atms-direct/get-analysis-results/" +
          element.atmsProjectId +
          "/" +
          element.uid
      )
      .then((response) => {
        let res = response.data;

        //console.log(res);
        //setStats();
        //setMtMatches(res);
        setStats(res);
        setOpen(true);
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Loaded", type: "success", loading: false }
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, []);
  const showDialogMessages = useCallback((element) => {
    element.languageNotes = element.languageNotes.sort(
      (a, b) => parseFloat(b.id) - parseFloat(a.id)
    );
    setSelectedElement(element);
    setOpenMessages(true);
  }, []);

  function handleClose() {
    setOpen(false);
  }

  function onValueChange(value) {
    setNote(value);
  }

  function handleCloseMessages() {
    setOpenMessages(false);
  }

  function handleCloseMulti() {
    setOpenMultiStats(false);
  }

  function addNote() {
    let data = {
      note: note,
      atmsTrackerLanguageId: selectedElement.id,
      atmsJobId: selectedElement.atmsJobId,
      name: selectedElement.name,
      fileName: selectedElement.fileName,
      language: selectedElement.targetLanguage
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Adding Note", loading: true }
    });
    api
      .post("/api/project-assist-api/add-note", data)
      .then((response) => {
        let res = response.data;

        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Note Added", type: "success", loading: false }
        });
        setOpenMessages(false);
        let rfNew = state.refresh + 1;
        setState((prevState) => ({
          ...prevState,
          refresh: rfNew
        }));
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {userStore.user_profile.role === "User" && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                {/*                <Button
                  disabled={multiEdit.length === 0}
                  onClick={() => acceptJobs()}
                  color={state.autoRefresh ? "success" : "secondary"}
                  variant={"outlined"}
                >
                  Accept selected jobs ({multiEdit.length})
                </Button>*/}
                <Button
                  disabled={multiComplete.length === 0}
                  onClick={() => completeJobs()}
                  color={state.autoRefresh ? "success" : "secondary"}
                  variant={"outlined"}
                >
                  Complete selected jobs ({multiComplete.length})
                </Button>
              </Grid>
              <Grid item></Grid>
              <Grid item>
                <Button
                  disabled={selectedItems.length === 0}
                  onClick={() => mergeStats()}
                  color={state.autoRefresh ? "success" : "secondary"}
                  variant={"outlined"}
                >
                  Show Combined WC ({multiStatsJobs.length})
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {userStore.user_profile.role === "Admin" && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  disabled={calculateWWCMulti.length === 0}
                  onClick={() => calculateMultiWeightWC()}
                  color={state.autoRefresh ? "success" : "secondary"}
                  variant={"outlined"}
                >
                  Calculate Weighted WC ({calculateWWCMulti.length})
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={multiStatsJobs.length === 0}
                  onClick={() => mergeStats()}
                  color={state.autoRefresh ? "success" : "secondary"}
                  variant={"outlined"}
                >
                  Show Combined WC ({multiStatsJobs.length})
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}></Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyDataGrid
            multiSelect={true}
            selectionId={"id"}
            selectionPayload={[
              "id",
              "atmsJobId",
              "checked",
              "statusForVendors",
              "weightedWordCount",
              "uid"
            ]}
            onSelectedItemsChange={setSelectedItems}
            AddMultiEdit={AddMultiEdit}
            RemoveMultiEdit={RemoveMultiEdit}
            options={tableOptions}
            url={"/api/atms-tracker/get-projects"}
            urlExport={["api/atms-tracker/export-csv"]}
            label={"log items"}
            renderItem={(item, index, selectionCheckbox) => (
              <Entry
                selectionCheckbox={selectionCheckbox}
                key={index}
                entry={item}
                onSelectProject={onSelectProject}
                selectedProject={selectedProject}
                showDialog={showDialog}
                showDialogMessages={showDialogMessages}
                acceptJob={acceptJob}
                onMultiEdit={onMultiEdit}
              />
            )}
            rowsPerPageOptions={[10, 20, 50, 100, 200, 400, 800]}
            rowsPerPage={10}
            refresh={state.refresh}
          />
        </Grid>
      </Grid>
      {stats !== null && stats !== undefined && (
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-edit-entry"
        >
          <DialogTitle id="alert-dialog-edit-entry">
            {"Weighted WC"}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell>Segments</TableCell>
                <TableCell>Words</TableCell>
                <TableCell>Percent</TableCell>
              </TableHead>
              <TableRow>
                <TableCell>All</TableCell>
                <TableCell>{stats.all.segments}</TableCell>
                <TableCell>{stats.all.words}</TableCell>
                <TableCell>{stats.all.percent}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Repetitions</TableCell>
                <TableCell>{stats.repetitions.segments}</TableCell>
                <TableCell>{stats.repetitions.words}</TableCell>
                <TableCell>{stats.repetitions.percentRounded}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>101%</TableCell>
                <TableCell>
                  {stats.transMemoryMatches.match101.segments}
                </TableCell>
                <TableCell>{stats.transMemoryMatches.match101.words}</TableCell>
                <TableCell>
                  {stats.transMemoryMatches.match101.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>100%</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match100.segments +
                    stats.transMemoryMatches.match100.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match100.words +
                    stats.transMemoryMatches.match100.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match100.percentRounded +
                    stats.transMemoryMatches.match100.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>95-99%</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match95.segments +
                    stats.transMemoryMatches.match95.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match95.words +
                    stats.transMemoryMatches.match95.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match95.percentRounded +
                    stats.transMemoryMatches.match95.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>85-94%</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match85.segments +
                    stats.transMemoryMatches.match85.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match85.words +
                    stats.transMemoryMatches.match85.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match85.percentRounded +
                    stats.transMemoryMatches.match85.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>75-84%</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match75.segments +
                    stats.transMemoryMatches.match75.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match75.words +
                    stats.transMemoryMatches.match75.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match75.percentRounded +
                    stats.transMemoryMatches.match75.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>50-74%</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match50.segments +
                    stats.transMemoryMatches.match50.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match50.words +
                    stats.transMemoryMatches.match50.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match50.percentRounded +
                    stats.transMemoryMatches.match50.percentRounded}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>New</TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match0.segments +
                    stats.transMemoryMatches.match0.segments}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match0.words +
                    stats.transMemoryMatches.match0.words}
                </TableCell>
                <TableCell>
                  {stats.machineTranslationMatches.match0.percentRounded +
                    stats.transMemoryMatches.match0.percentRounded}
                </TableCell>
              </TableRow>
            </Table>

            {stats.weighteddWC.length > 0 && (
              <Typography>
                Weighted WC: {formatNumber(weightedWords(stats))}{" "}
              </Typography>
            )}
            {stats.weighteddWC.length === 0 && (
              <Typography>
                Unable to calculate Weighted WC: user account is not associated
                with any XTRF user accounts.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {multiStats !== null && multiStats !== undefined && (
        <Dialog
          maxWidth={"sm"}
          open={openMultistats}
          onClose={handleCloseMulti}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-edit-entry"
        >
          <DialogTitle id="alert-dialog-edit-entry">
            {"Combined Word Count"}
          </DialogTitle>
          <DialogContent>
            {analysisErrorMessage.length > 0 && (
              <Typography style={{ color: "#d32f2f" }}>
                Could not load stats for project:
                {analysisErrorMessage}
              </Typography>
            )}
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell>Segments</TableCell>
                <TableCell>Words</TableCell>
              </TableHead>
              <TableRow>
                <TableCell>All</TableCell>
                <TableCell>{multiStats.all.segments}</TableCell>
                <TableCell>{multiStats.all.words}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Repetitions</TableCell>
                <TableCell>{multiStats.repetitions.segments}</TableCell>
                <TableCell>{multiStats.repetitions.words}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>101%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match101.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match101.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>100%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match100.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match100.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>95-99%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match95.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match95.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>85-94%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match85.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match85.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>75-84%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match75.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match75.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>50-74%</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match50.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match50.words}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>New</TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match0.segments}
                </TableCell>
                <TableCell>
                  {multiStats.transMemoryMatches.match0.words}
                </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMulti}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedElement != null && (
        <Dialog
          maxWidth={"sm"}
          open={openMessages}
          onClose={handleCloseMessages}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-edit-entry"
        >
          <DialogTitle id="alert-dialog-edit-entry">{"Messages"}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              fullHeight={true}
              label="Message"
              value={note}
              onChange={(event) => onValueChange(event.target.value)}
              multiline
              height="200px"
            />
            <Button style={{ display: "block" }} onClick={() => addNote()}>
              Save
            </Button>
            {selectedElement.languageNotes !== undefined &&
              selectedElement.languageNotes.map((message, index) => (
                <React.Fragment key={message.id}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      className={classes.messageDetails}
                    >
                      Created On:{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.messageDetails}
                    >
                      <DateTimeEntry dateTime={message.createdOn} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      className={classes.messageDetails}
                    >
                      Created By:{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.messageDetails}
                    >
                      {message.addedBy}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">Message: </Typography>
                    <Typography variant="caption" display="block">
                      {message.note}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="horizontal"
                    className={classes.messageDivider}
                  />
                </React.Fragment>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMessages}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
