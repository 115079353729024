import { Link, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { DateTimeEntry } from "./DateTimeEntry";
import { HomeAppContext } from "../Home";
import LinearProgressWithLabel from "../General/LinearProgressWithLabel";
import { formatNumber } from "../../Services/constantsAndTools";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MessageIcon from "@mui/icons-material/Message";
import IconButton from "@mui/material/IconButton";

const Entry = ({
  entry,
  selectedProject,
  onSelectProject,
  managers,
  statuses,
  showDialog,
  showDialogMessages,
  acceptJob,
  onMultiEdit,
  selectionCheckbox
}) => {
  const { homeDispatch } = useContext(HomeAppContext);

  const [localEntry, setLocalEntry] = useState(null);

  useEffect(() => {
    setLocalEntry({
      ...entry,
      id: entry.id,
      name: entry.name,
      fileName: entry.fileName,
      division: entry.division,
      dateCreated: entry.dateCreated,
      totalWC: entry.wordCount,
      totalWCWeighted: entry.weightedWordCount,
      dueDate: entry.dueDate,
      status: entry.status,
      uid: entry.uid,
      sourceLanguage: entry.sourceLanguage,
      workflowStep: entry.workflowStep,
      vendorDeadline: entry.vendorDeadline,
      atmsJobId: entry.atmsJobId,

      percentCompleted: entry.percentCompleted,
      statusForVendors: entry.statusForVendors,
      atmsUserName: entry.atmsUserName,
      selected: entry.selected,
      reviewDeadline: entry.reviewDeadline,
      batchId: entry.batchId,
      weightedWordCount: entry.weightedWordCount,
      showCalculateButton: false,

      _changed: 0
    });
  }, [entry]);

  function openUrl(localEntry) {
    let url = process.env.REACT_APP_ATMS_PROJECT_URL + localEntry.uid;
    window.open(url, "_blank");
  }

  function calculateWWC(localEntry) {
    setLocalEntry({
      ...localEntry,
      showCalculateButton: true
    });
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Calculating", loading: true }
    });
    let jobs = api
      .get(
        "/api/atms-direct/calculate-wighted-wc/" +
          localEntry.atmsProjectId +
          "/" +
          localEntry.uid +
          "/" +
          localEntry.atmsJobId
      )
      .then((response) => {
        console.log(response.data);
        var res = response.data;

        if (res !== 0) {
          setLocalEntry({
            ...localEntry,
            weightedWordCount: res,
            showCalculateButton: false
          });
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Calculated", type: "success", loading: false }
          });
        } else {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "Could not calculate weighted word count",
              type: "warning",
              loading: false
            }
          });
          setLocalEntry({
            ...localEntry,
            showCalculateButton: false
          });
        }
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }

  return (
    localEntry !== null && (
      <React.Fragment>
        <TableRow>
          <TableCell>{selectionCheckbox}</TableCell>
          <TableCell align={"left"}>
            <Tooltip title={localEntry.languageNotes.length ?? 0}>
              <IconButton
                onClick={() => showDialogMessages(localEntry)}
                size={"small"}
                color="secondary"
                aria-label="edit user"
              >
                <MessageIcon />
              </IconButton>
            </Tooltip>
          </TableCell>

          <TableCell align={"left"}>{localEntry.batchId}</TableCell>
          <TableCell>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    {localEntry.name}
                  </Typography>
                </React.Fragment>
              }
            >
              <Button onClick={() => openUrl(localEntry)}>Open</Button>
            </Tooltip>
          </TableCell>
          <TableCell align={"left"}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    {localEntry.fileName}
                  </Typography>
                </React.Fragment>
              }
            >
              <Link
                href={`${process.env.REACT_APP_ATMS_PROJECT_URL_TRANSLATION.replace(
                  "[id]",
                  localEntry.atmsJobId
                )}`}
                target={"_blank"}
                variant="body2"
              >
                {localEntry.fileName != null &&
                  localEntry.fileName.substring(0, 20)}
              </Link>
            </Tooltip>
          </TableCell>
          <TableCell align={"left"}>{localEntry.division}</TableCell>
          <TableCell align={"left"}>{localEntry.sourceLanguage}</TableCell>
          <TableCell align={"left"}>{localEntry.targetLanguage}</TableCell>
          <TableCell align={"left"}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    Click to show WC Breakdown
                  </Typography>
                </React.Fragment>
              }
            >
              <Button onClick={() => showDialog(localEntry)}>
                {localEntry.totalWC}
              </Button>
            </Tooltip>
          </TableCell>
          {/*<TableCell align={"left"}>*/}
          {/*  <Button onClick={() => showDialog(localEntry)}>Show</Button>*/}
          {/*</TableCell>*/}
          <TableCell align={"left"}>
            {localEntry.weightedWordCount != null &&
              formatNumber(localEntry.weightedWordCount)}
            {localEntry.weightedWordCount == null && (
              <Button
                onClick={() => calculateWWC(localEntry)}
                disabled={localEntry.showCalculateButton}
              >
                Calculate
              </Button>
            )}
          </TableCell>
          <TableCell align={"left"}>
            <LinearProgressWithLabel
              variant={"determinate"}
              color="primary"
              value={localEntry.percentCompleted}
            />
          </TableCell>
          <TableCell align={"left"}>{localEntry.workflowStep}</TableCell>
          <TableCell align={"left"}>
            {localEntry.statusForVendors}
            {/*          {(localEntry.statusForVendors === "NEW" ||
              localEntry.statusForVendors === "EMAILED") && (
              <Button onClick={() => acceptJob(localEntry)}>Accept</Button>
            )}*/}
          </TableCell>
          <TableCell align={"left"}>
            <DateTimeEntry dateTime={localEntry.vendorDeadline} />
          </TableCell>
          <TableCell align={"left"}>
            <DateTimeEntry dateTime={localEntry.reviewDeadline} />
          </TableCell>
          <TableCell align={"left"}>{localEntry.atmsUserName}</TableCell>
        </TableRow>
      </React.Fragment>
    )
  );
};

export default Entry;
