import moment from "moment-timezone";
import { Tooltip } from "@mui/material";
import React from "react";

export const DateTimeEntry = ({ dateTime }) => {
  const dateTimeUtc =
    dateTime !== undefined && dateTime !== null ? moment.utc(dateTime) : null;
  const dateTimeLocal =
    dateTime !== undefined && dateTime !== null
      ? moment.utc(dateTime).local()
      : null;

  return dateTimeLocal !== null ? (
    <Tooltip
      title={
        <React.Fragment>
          {dateTimeLocal?.format("DD MMM YYYY HH:mm") + " (local)"}
          <br />
          {dateTimeUtc?.format("DD MMM YYYY HH:mm") + " (UTC)"}
        </React.Fragment>
      }
    >
      <div style={{ display: "inline" }}>
        {dateTimeLocal?.format("DD MMM YYYY HH:mm") ?? "N/A"}
      </div>
    </Tooltip>
  ) : (
    <div>N/A</div>
  );
};
