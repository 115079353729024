import { amber, blue, blueGrey, cyan, green, red } from "@mui/material/colors";

import { GeValueNotAvailable } from "../Components/General/GeneralIcons";
import React from "react";
import { hslToRgb } from "@mui/material/styles";

import seedrandom from "seedrandom";
import BigNumber from "bignumber.js";

export const defaultDateFormat = "YYYY-MM-DD HH:mm";

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const NotificationVariation = {
  COMPOUND: "compound",
  SIMPLE: "simple"
};

const findFieldsWithErrors = (entity, fields) =>
  fields.reduce((acc, field) => {
    if (field?.fields !== undefined) {
      const internal = findFieldsWithErrors(entity[field.name], field.fields);
      if (Object.entries(internal).length === 0) {
        return acc;
      } else {
        return {
          ...acc,
          [field.name]: internal
        };
      }
    } else {
      if (field.arrayKey === undefined) {
        if (
          field.invalidValues.some((x) =>
            x instanceof RegExp
              ? entity[field.name].match(x) === null
              : x === entity[field.name]
          ) &&
          (field.conditions === undefined ||
            field.conditions.every((x) => entity[x.name] === x.value))
        ) {
          return { ...acc, [field.name]: [field.message] };
        } else {
          return acc;
        }
      } else {
        const arrayErrors = entity[field.name].reduce((accAr, fieldAr) => {
          if (
            field.invalidValues.some((x) =>
              x instanceof RegExp
                ? fieldAr[fieldAr.arrayProperty].match(x) === null
                : x === fieldAr[field.arrayProperty]
            )
          ) {
            return [
              ...accAr,
              {
                [field.arrayKey]: fieldAr[field.arrayKey],
                property: field.arrayProperty,
                message: field.message
              }
            ];
          } else {
            return accAr;
          }
        }, []);
        if (arrayErrors.length !== 0) {
          return { ...acc, [field.name]: arrayErrors };
        } else {
          return acc;
        }
      }
    }
  }, {});

export const checkErrorsOnEntity = (entity, fields) =>
  findFieldsWithErrors(entity, fields);

export const SegmentStatusColors = {
  Unset: blueGrey[400],
  Saved: amber[400],
  Approved: green[400],
  Edited: cyan[400],
  ApprovedEdition: blue[400]
};
export const weightedWords = (entry) => {
  const sum = entry.weighteddWC.reduce((acc, x) => {
    const rate = new BigNumber(x.rate);
    return acc.plus(rate.times(x.quantity));
  }, BigNumber(0));
  return sum.dividedBy(100).toPrecision(5);
};
export const ReferenceURLsStatusColors = {
  Total: blueGrey[400],
  Retry: amber[400],
  Reachable: green[400],
  NotReachable: red[400]
};

export default NotificationVariation;

export const valueOrNa = (
  value,
  onlyIcon = true,
  message = "Not available"
) => {
  if (value !== "" && value !== null && value !== undefined) return value;
  return onlyIcon ? (
    <GeValueNotAvailable
      titleAccess="Not Available"
      style={{ fontSize: "inherit" }}
    />
  ) : (
    <span style={{ fontStyle: "italic" }}>
      <GeValueNotAvailable
        titleAccess="Not Available"
        style={{ fontSize: "inherit", verticalAlign: "middle", marginRight: 4 }}
      />
      {message}
    </span>
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatText = (string) => {
  return string
    ? string.split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })
    : "";
};

const stripDiacritics = (string) => {
  return typeof string.normalize !== "undefined"
    ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    : string;
};

const defaultStringifyOption = (option) => `${option.text}`;

export const optionsFilter = (options, inputValue, stringifyOption = null) => {
  const ignoreAccents = true;
  const ignoreCase = true;
  const limit = false;
  const matchFrom = "any";
  const stringify =
    stringifyOption === null ? defaultStringifyOption : stringifyOption;
  const trim = false;

  let input = trim ? inputValue.trim() : inputValue;
  if (ignoreCase) {
    input = input.toLowerCase();
  }
  if (ignoreAccents) {
    input = stripDiacritics(input);
  }

  const filteredOptions = options.filter((option) => {
    let candidate = stringify(option);
    if (ignoreCase) {
      candidate = candidate.toLowerCase();
    }
    if (ignoreAccents) {
      candidate = stripDiacritics(candidate);
    }

    return matchFrom === "start"
      ? candidate.indexOf(input) === 0
      : candidate.indexOf(input) > -1;
  });

  return typeof limit === "number"
    ? filteredOptions.slice(0, limit)
    : filteredOptions;
};

const defaultArrayFilterStringifyOption = (option) => [`${option.text}`];

export const arrayFilter = (options, inputValue, stringifyOption = null) => {
  const ignoreAccents = true;
  const ignoreCase = true;
  const limit = false;
  const matchFrom = "any";
  const stringify =
    stringifyOption === null
      ? defaultArrayFilterStringifyOption
      : stringifyOption;
  const trim = false;

  let input = trim ? inputValue.trim() : inputValue;
  if (ignoreCase) {
    input = input.toLowerCase();
  }
  if (ignoreAccents) {
    input = stripDiacritics(input);
  }

  const filteredOptions = options.filter((option) => {
    let candidates = stringify(option);
    if (ignoreCase) {
      candidates = candidates.map((x) => x.toLowerCase());
    }
    if (ignoreAccents) {
      candidates = candidates.map((x) => stripDiacritics(x));
    }

    return matchFrom === "start"
      ? candidates.some((x) => x.indexOf(input) === 0)
      : candidates.some((x) => x.indexOf(input) > -1);
  });

  return typeof limit === "number"
    ? filteredOptions.slice(0, limit)
    : filteredOptions;
};

//golden ratio const
const PHI = 1.618033988749895;
export const generateColorFromString = (str) =>
  hslToRgb(
    `hsl(${Math.floor(((seedrandom(str)() + 1 / PHI) % 1) * 360)}, 50%, 50%)`
  );

export const generatePastelColor = () => {
  let R = Math.floor(Math.random() * 127 + 127);
  let G = Math.floor(Math.random() * 127 + 127);
  let B = Math.floor(Math.random() * 127 + 127);

  let rgb = (R << 16) + (G << 8) + B;
  return `#${rgb.toString(16)}`;
};

export const formatNumber = (value, longFormat = true) =>
  longFormat
    ? Intl.NumberFormat("en-Us").format(value)
    : Intl.NumberFormat("en-Us", {
        notation: "compact",
        compactDisplay: "short"
      }).format(value);

export const formatCurrency = (value, longFormat = true, currency = "USD") =>
  longFormat
    ? Intl.NumberFormat("en-Us", {
        style: "currency",
        currency: currency
      }).format(value)
    : Intl.NumberFormat("en-Us", {
        notation: "compact",
        compactDisplay: "short",
        style: "currency",
        currency: currency
      }).format(value);

export const formatCurrencyAlt = (value, longFormat = true, symbol = "$") =>
  longFormat
    ? symbol +
      " " +
      Intl.NumberFormat("en-Us", { minimumFractionDigits: 2 }).format(value)
    : symbol +
      " " +
      Intl.NumberFormat("en-Us", {
        notation: "compact",
        compactDisplay: "short",
        maximumSignificantDigits: 2
      }).format(value);
